<template>
  <div>
    <b-card>
      <h4>Details</h4>

      <div class="d-flex p-1">
        <div class="w-100">
          <b-row>
            <b-col cols="3">
              Purchase For
            </b-col>
            <b-col>
              {{ checkout.delivery_address.purchase_made_for }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              Payment Method
            </b-col>
            <b-col>
              {{ checkout.payment.method }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              Recipient
            </b-col>
            <b-col>
              {{ checkout.delivery_address.recipient }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              Phone Number
            </b-col>
            <b-col>
              {{ checkout.delivery_address.phone_number }}
            </b-col>
          </b-row>
        </div>

        <!-- Delivery Details -->
        <div class="w-100">
          <b-row>
            <b-col cols="3">
              Delivery Address
            </b-col>
            <b-col>
              {{ checkout.delivery_address.address }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              Delivery State
            </b-col>
            <b-col>
              {{ checkout.delivery_address.state }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              Delivery Country
            </b-col>
            <b-col>
              {{ checkout.delivery_address.country }}
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              Delivery Postal Code
            </b-col>
            <b-col>
              {{ checkout.delivery_address.postal_code }}
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>

    <div class="list-view product-checkout mt-0">

      <!-- Products List -->
      <e-commerce-checkout-step-cart-products
        :product-in-cart="productInCart"
        :is-editable="false"
        @refresh-cart="$emit('refresh-cart')"
      />

      <!-- Checkout Options -->
      <div  v-if="(checkout.status == 'Received')"
      class="checkout-options">
        <b-card>
          <div class="price-details">
            <b-form-rating
                no-border
                v-model="value"
                variant="warning"
                size="lg"
              />
              <hr>
              <b-form-textarea
                id="textarea-rows"
                v-model="message"
                placeholder="Review your product"
                rows="8"
              />
              <hr>
            <b-button
              variant="primary"
              block
              @click="review(productId, value, message)"
            >
              Review
            </b-button>
          </div>

        </b-card>
      </div>
    </div>
  </div> 
</template>

<script>
import ECommerceCheckoutStepCartProducts from '../MarketplaceCheckoutStepCartProducts.vue'

export default {
  components: {
    ECommerceCheckoutStepCartProducts,
  },
  data() {
    return {
      checkout: {},
      productInCart: [],
      totalPrice: 0,
      subtotalPrice: 0,
      productId : '',
      value : 0,
      message : ''
    }
  },
  created() {
    this.get_checkout_details()
  },
  methods: {
    get_checkout_details() {
      const id = this.$route.params.id

      this.$http
        .get(`/api/marketplace/checkouts/${id}`)
        .then(response => {
          const { success, output } = response.data

          if (success) {
            this.checkout = output
            this.productInCart = this.checkout.cart
            this.productInCart.map(key => this.productId = key.product_id)
            this.subtotalPrice = this.checkout.subtotal_price
            this.totalPrice = this.checkout.total_price
          } else {
            this.productInCart = []
            this.subtotalPrice = 0
            this.totalPrice = this.subtotalPrice
          }
        })
        .catch(() => {})
    },

    review(id,rate,message){

      const post_data = new FormData()
      post_data.append('product_id',  id)
      post_data.append('rate',  rate)
      post_data.append('message',  message)

      this.$http
        .post(`/api/marketplace/review/`, post_data)
        .then(response => {
              const { success } = response.data
              const { message } = response.data
              this.isLoading = false
              if (success) {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Orders Notification',
                      icon: 'AlertCircleIcon',
                      text: message,
                      variant: 'success',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
                this.updateToReviewed()
                this.$router.push('/marketplace-my-checkouts')
              } else {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Orders Notification',
                      icon: 'AlertCircleIcon',
                      text: `Can't Add Review, try again later`,
                      variant: 'error',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
              }
            })
        .catch(() => {})

    },

    updateToReviewed(){

      let new_status = 'Reviewed'

      const post_data = new FormData()
      post_data.append('status',  new_status)
      post_data.append('checkout_id',  this.$route.params.id)

      this.$http
            .post(`/api/marketplace/edit-checkout-status`, post_data)
            .then(response => {
              const { success } = response.data
              const { message } = response.data
              this.isLoading = false
              if (success) {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Orders Notification',
                      icon: 'AlertCircleIcon',
                      text: message,
                      variant: 'success',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
                this.$router.push('/marketplace-orders')
              } else {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Transaction Notification',
                      icon: 'AlertCircleIcon',
                      text: `Can't update Trans, try again later`,
                      variant: 'error',
                    },
                  },
                  { timeout: this.$longestTimeout },
                )
              }
            })
            .catch(error => {
              alert('ERROR')
            })
    }

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>
